import React from 'react';
import './Splash.css';

function Splash() {
  return (
    <div className="Splash">
      <iframe
          title="splash_video-srlovejourney"
          frameBorder={0}
          allow="autoplay; encrypted-media; accelerometer; gyroscope; picture-in-picture"
          allowFullScreen={true}
          src="https://www.youtube.com/embed/2sAg8FhPesc?rel=0&controls=0&autoplay=1&mute=0&start=0">
      </iframe>
    </div>
  );
}

export default Splash;
